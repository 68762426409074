import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Back Squat 4×6\\@80% 1RM`}</p>
    <p>{`Single Leg Hamstring Curl 4×6/leg`}</p>
    <p>{`then,`}</p>
    <p>{`3 Rounds for time:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`15 Box Jumps (24/20)`}</p>
    <p>{`15 Back Squats (185/125)`}</p>
    <p>{`15 GHD Situps`}</p>
    <p><em parentName="p">{`*`}{`compare to 3/4/18`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday is our free partner wod at 9:00 & 10:00am so come and
bring a friend!  The Color Run is also this Saturday so plan on leaving
the house early and parking on Market or a side street.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The Granite Games Fall Throwdown is September 14th and registration
is now live!  Get your team of 3 together and register
at:`}</em></strong><a parentName="p" {...{
        "href": "https://competitioncorner.net/events/2396#.XQlcOa2ZPys"
      }}>{`https://competitioncorner.net/events/2396#.XQlcOa2ZPys`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      